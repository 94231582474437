<template>
  <div class="successCase">
    <div class="banner">
      <img src="@/assets/img/case/banner.png" />
    </div>
    <div class="mainContent">
      <div class="side">
        <div class="tree">
          <div class="first" v-for="(v, k) in sideTreeList">
            <div class="title">
              <img :src="require(`@/assets/img/terminal/treeNode.svg`)" v-if="checkIndex == k" />
              <div v-else style="width: 0.14rem"></div>
              <p :class="checkIndex == k ? 'detailBg' : ''" @click="handleClick(v, k)">
                {{ v.label }}
              </p>
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="right">
        <div class="details">
          <div class="detail_item">
            <img :src="currentItem.banner" class="detail_banner" />
            <h3 class="detail_title">
              <p>{{ currentItem.title }}</p>
              <span></span>
            </h3>
            <div class="contentList">
              <div class="contentItem" v-for="item in currentItem.contentList">
                <span class="subtitle" v-if="item.subtitle">{{ item.subtitle }}</span>
                <div class="text" v-html="item.text"></div>
              </div>
            </div>
            <div v-if="currentItem.projectImage.length" class="projectImage">
              <img :src="img" v-for="(img, index) in currentItem.projectImage" :key="index" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { caseList } from '@/assets/data/case'
export default {
  name: 'case',
  components: {},
  watch: {
    $route(to, from) {
      if (to.path === '/case') {
        this.checkIndex = this.sideTreeList.findIndex((v) => v.label == to.query.caseName)
        this.checkIndex = this.checkIndex === -1 ? 0 : this.checkIndex
        this.currentItem = this.sideTreeList[this.checkIndex]
      }
    },
  },
  computed: {
    sideTreeList() {
      return caseList
    },
  },
  data() {
    return {
      checkIndex: 0,
      currentItem: {},
    }
  },
  methods: {
    handleClick(item, index) {
      this.checkIndex = index
      this.currentItem = this.sideTreeList[index]
      this.$router.push({
        path: '/case',
        query: {
          caseName: item.label,
        },
      })
    },
  },
  created() {
    this.checkIndex = this.sideTreeList.findIndex((v) => v.label == this.$route.query.caseName)
    if (this.checkIndex == -1) {
      this.checkIndex = 0
      this.$router.push('/case')
    }
    this.currentItem = this.sideTreeList[this.checkIndex]
  },
  mounted() {},
}
</script>
<style lang="less">
.successCase {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
  .banner {
    width: 100%;
    height: 3.9rem;
    position: relative;
    background: #eceaec;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .mainContent {
    padding: 0 3.57rem 0.6rem;
    display: flex;
    justify-content: space-between;
    .side {
      position: relative;
      flex-shrink: 0;
      width: 2.87rem;
      .tree {
        margin-top: 1.08rem;
        .first {
          font-size: 0.18rem;
          color: #333333;
          margin-bottom: 0.2rem;
          .title {
            display: flex;
            cursor: pointer;
            align-items: center;
            img {
              width: 0.14rem;
              height: 0.14rem;
              margin-right: 0.07rem;
              z-index: 10;
            }
            p {
              flex: 1;
              padding-left: 0.13rem;
              font-size: 0.16rem;
              height: 0.3rem;
              line-height: 0.3rem;
              cursor: pointer;
              &:hover {
                color: #00a4ea;
              }
              word-break: keep-all;
              &.detailBg {
                background-image: url('../assets/img/terminal/detailBg.svg');
                background-size: cover;
                color: #fff;
                &:hover {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .line {
        width: 0.04rem;
        height: 2rem;
        background: #c9cdd4;
        border-radius: 0rem 0rem 0rem 0rem;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0.05rem;
        height: 100%;
      }
    }
    .right {
      width: 9rem;
      padding-top: 0.8rem;
      .details {
        .detail_item {
          margin-top: 0.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:nth-child(1) {
            margin-top: 0;
          }
          .detail_banner {
            width: 9rem;
            height: 3.86rem;
            margin-bottom: 0.5rem;
          }
          .detail_title {
            display: inline-block;
            margin: 0 auto;
            padding: 0 0.1rem;
            position: relative;
            p {
              font-size: 0.28rem;
              font-weight: bold;
              color: #000000;
              line-height: 0.4rem;
              letter-spacing: 0.03rem;
              position: relative;
              z-index: 1;
            }
            span {
              display: inline-block;
              width: 100%;
              height: 0.13rem;
              position: absolute;
              bottom: 0;
              left: 0;
              background: #00a4ea;
              z-index: 0;
            }
          }
          .contentList {
            font-size: 12px;
            .contentItem {
              margin-top: 0.3rem;
              .subtitle {
                font-size: 0.18rem;
                font-weight: bold;
                color: #ffffff;
                line-height: 0.28rem;
                padding: 0 0.18rem 0 0.1rem;
                background: linear-gradient(90deg, #4481eb, #04befe);
                border-radius: 0 0.13rem 0 0;
                margin-bottom: 0.2rem;
              }
              .text {
                font-size: 0.16rem;
                font-weight: 400;
                color: #333333;
                line-height: 0.26rem;
                margin-top: 0.2rem;
                p {
                  text-indent: 2em;
                }
              }
            }
          }
          .projectImage {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-top: 0.3rem;
            column-gap: 0.5rem;
            img {
              flex: 1 0;
            }
          }
        }
      }
    }
  }
}
</style>
