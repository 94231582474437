// 智慧医疗解决方案数据
export const caseList = [
  {
    label: "甘肃省人民医院新区分院",
    title: "兰州银行关于甘肃省人民医院新区分院银医通项目",
    banner: require("@/assets/img/case/detail_banner_gssy.png"),
    contentList: [
      {
        subtitle: "医院概况",
        text: `<p>甘肃省人民医院兰州新区分院是一家国家三级甲等医院，项目总用地面积327446.1平方米，其中建设用地面积220020.6平方米， 建设内容包括门急诊楼、医技楼、住院楼一期、氧气站、供应室、动力、排污站、康复养护中心、失能、半失能老人养护楼等以及公租房、职工住宅楼。根据规划，兰州新区分院远期规划医疗床位数2000张、老年康复养护床位数1500张。兰州新区分院拟建设成为集医疗、康复、疗养、科研、教学为一体的综合性、现代化的国家三级甲等医院。也将成为全国唯一一家拥有养老功能的三级甲等医院。</p>`,
      },
      {
        subtitle: "项目背景",
        text: `<p>甘肃省人民医院新区为新建院区，分院为了提高医疗服务效率和质量，平均优质医疗资源，减少患者就医时间，改善患者体验，计划引入银医通项目。银医通是一种将银行业务与医疗服务相结合的综合性解决方案，旨在为医院提供便捷、高效的金融服务，同时为患者提供更加完善的医疗与金融体验。为了满足医院的需求，兰州银行决定为甘肃省人民医院新区分院提供银医通项目的建设方案。</p>`,
      },
      {
        subtitle: "建设目标",
        text: `<ul>
          <li>1、为医院提供便捷、高效的金融服务，包括患者挂号、缴费、查询等功能，提高医院内部运营效率。</li>
          <li>2、通过银医通项目，为患者提供更加便捷的医疗服务，减少排队和等待时间，改善就医体验。</li>
          <li>3、实现银行与医院之间的信息共享和业务协同，提高双方工作效率和合作紧密度。</li>
          <li>4、实现自助终端的统一支付及统一对账，整合多种支付方式，快速完成对账任务。</li>
        </ul>`,
      },
      {
        subtitle: "项目效果",
        text: `<ul>
          <li>1、提高医院的医疗服务效率和质量，缩短患者就医时间，改善患者体验。</li>
          <li>2、实现银行与医院之间的信息共享和业务协同，提高双方工作效率和合作紧密度。</li>
          <li>3、通过数据分析和挖掘，为医院和患者提供个性化、精准的金融与医疗服务，提升双方满意度。</li>
          <li>4、提升兰州银行在甘肃省人民医院新区分院的品牌形象和服务质量。</li>
          <li>5、通过信息安全保障措施，确保项目实施过程中的信息安全和合规性。</li>
        </ul>`,
      },
    ],
    projectImage: [require("@/assets/img/case/project_gssy.png")],
  },
  {
    label: "新疆自治区友谊医院",
    title: "乌鲁木齐市友谊医院",
    banner: require("@/assets/img/case/detail_banner_yyyy.png"),
    contentList: [
      {
        subtitle: "医院概况",
        text: `<p>乌鲁木齐市友谊医院创建于1948年，是乌鲁木齐市一所三级甲等医院。医院设置科室有内科（急诊、心内、CCU、ICU、呼吸、消化、神经内、干部病房、内分泌、肾内、血液、老年病专业）等14个医技科近30个检查治疗室和一所科研实验示教中心。</p>`,
      },
      {
        subtitle: "项目背景",
        text: `<p>目前，医院面临着医疗需求不断增加、就医患者数量持续增长的实际处境，并且随着社会发展和人们健康意识的提高，为了满足患者的需求，提高医疗服务的质量和效率，乌鲁木齐市友谊医院需要更新和完善其服务系统。</p>
        <p>自助服务系统可以为患者提供更加便捷、高效的医疗服务。患者可以通过自助服务系统完成预约挂号、缴费、查询等操作，减少排队等待时间，提高就医体验。医院决定进行自助服务系统的建设，以更好地掌握患者需求和资源使用情况，实现资源的优化配置。这有助于提高医院的管理水平和运营效率。</p>
        `,
      },
      {
        subtitle: "建设目标",
        text: `<ul>
          <li>1、服务效率：通过自助服务系统，患者可以更加便捷地完成预约挂号、缴费、查询等操作，减少排队等待时间，提高医院的服务效率。</li>
          <li>2、提升服务质量：自助服务系统可以提供24小时服务，患者可以根据自己的时间安排就医，减少等待时间，提高就医体验。</li>
          <li>3、优化医疗资源管理：通过自助服务系统，医院可以更好地掌握患者需求和资源使用情况，实现资源的优化配置和管理，提高医疗资源的利用效率。</li>
          <li>4、增强患者参与度：自助服务系统可以提供多种渠道和方式让患者参与医疗服务过程，增加患者与医院的互动和参与度。</li>
          <li>5、提升医院品牌形象：建设自助服务系统可以提升乌鲁木齐市友谊医院的品牌形象和服务质量，增强患者对医院的信任度和满意度。</li>
        </ul>`,
      },
      {
        subtitle: "项目效果",
        text: `<p>通过本项目，有效的解决了因为医疗资源供给相对不足，导致就医“三长一短”的突出问题，减少排队等待时间，患者可通过自助服务系统，患者可以自行完成预约挂号、缴费、查询等操作，无需排队等待，节省了时间，实现“把看病的事情交给医生做，把流程的事请交给机器做”。</p>`,
      },
    ],
    projectImage: [
      require("@/assets/img/case/project_yyyy_1.png"),
      require("@/assets/img/case/project_yyyy_2.png"),
    ],
  },
  {
    label: "新疆维吾尔自治区人民医院",
    title: "新疆维吾尔自治区人民医院“自助医院”项目",
    banner: require("@/assets/img/case/detail_banner_zzqrmyy.png"),
    contentList: [
      {
        text: `<p>“自助医院”——引领医疗新潮流，智能服务触手可及！</p>
        <p>为了更好地满足患者的医疗需求，新疆维吾尔自治区人民医院正式推出“自助医院”项目！通过这一创新项目，为患者提供24小时不间断的自助服务，让患者享受到更便捷、高效、温馨的医疗服务。</p>
        <p>“自助医院”是指通过引入智能化自助服务系统，将传统医疗流程与现代信息技术相结合，为患者提供自助挂号、缴费、查询、取报告等服务的医疗机构。在“自助医院”，您无需长时间排队等待，只需使用自助终端即可轻松完成各项操作。</p>
        `,
      },
      {
        subtitle: "我们能带来：",
        text: `<ul>
          <li>1、节省时间：通过自助服务系统，患者可以快速的完成挂号、缴费、查询等操作，避免长时间排队等待，节省宝贵的时间。</li>
          <li>2、提高效率：自助服务系统采用智能化操作流程，能够快速、准确地处理患者的需求，提高医院的服务效率。</li>
          <li>3、个性化服务：患者可以根据自己的需求和时间安排进行挂号、缴费等操作，享受个性化的医疗服务。</li>
          <li>4、24小时服务：自助服务系统提供24小时不间断服务，无论何时，患者都可以方便地获取医疗服务。</li>
          <li>5、医院信息获取：通过自助机浏览查询方式，患者可以获取最新医疗资讯、专家建议等信息。</li>
        </ul>`,
      },
      {
        text: `<p>“自助医院”一上线，便成为门诊患者“新宠”，极大程度地取代人工窗口，无论检验费、治疗费还是药费，患者均可使用中国国内商业银行发行的任一借记卡或信用卡自助缴费，设备还可实现医保换号和医保缴费，最大程度地便利患者缴费。</p>`,
      },
    ],
    projectImage: [
      require("@/assets/img/case/project_zzqrmyy_1.png"),
      require("@/assets/img/case/project_zzqrmyy_2.png"),
    ],
  },
  {
    label: "西安交通大学口腔医院",
    title: "西安交通大学口腔医院智慧医院项目",
    banner: require("@/assets/img/case/detail_banner_jdkq.png"),
    contentList: [
      {
        text: `<p>西安交通大学口腔医院（口腔医学院）是国家卫生健康委直属的集医疗、教学、科研、预防保健为一体的三级甲等口腔专科医院，是西北地区口腔医学专业师资培训中心，是国家卫健委继续医学教育基地、国家口腔执业医师实践技能考试与考官培训基地、国家口腔住院医师规范化培训基地、国家药物临床试验机构资格单位，陕西省牙颌疾病临床研究中心，陕西省口腔医疗质量控制中心及陕西省医师协会口腔医师分会挂靠单位。</p>
        <p>目前医院在西安有6家跨区域的门诊部，每日接诊量巨大，而医院原有的智慧服务系统已经不能满足现有的需求，导致门诊患者有堆积、混乱的想象，同时医院也投入了大量的人力，但是效果却未能达到预期，在经过院方和专家讨论后决定引入智慧医院服务系统来解决以上问题。</p>
        <p>德卡智慧医院自助服务系统，提供全新的医疗体验！通过引入先进的科技和智能化设备，为医院打造高效、便捷、舒适的医疗环境，让患者的就医过程更加顺畅、省心。</p>
        `,
      },
      {
        subtitle: "24小时自助服务，随时随地解决就医问题",
        text: `<p>德卡智慧医院自助服务系统提供24小时全天候服务，让患者随时解决就医问题。患者可以通过自助服务系统进行预约挂号、查询科室位置、了解医生排班情况等操作，不再需要长时间排队等待。</p>`,
      },
      {
        subtitle: "智能化设备，提升就医效率",
        text: `<p>我们引入了最先进的智能化设备，包括自助挂号机、自助缴费机等，让患者能够快速完成各项操作，提高就医效率。同时，患者可以在一站式自助服务区完成挂号、缴费、报告打印等环节，减少等待时间。</p>
        <p>德卡智慧医院自助服务系统致力于打造高效、便捷、舒适的医疗环境，让患者的就医过程更加顺畅、省心。通过引入先进的科技和智能化设备，我们不断提升服务质量和医疗水平，为每一位患者带来更好的就医体验。选择德卡智慧医院自助服务系统，让我们一起迈向智能化的医疗新时代！</p>
        `,
      },
    ],
    projectImage: [require("@/assets/img/case/project_jdkq.svg")],
  },
];
